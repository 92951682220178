import { styled, Typography } from '@mui/material';

const StyledLink = styled('a')(({ theme }) => ({
    color: theme.palette.text.primary
}));

type ArtistCreditProps = {
    artistName?: string;
    artistUrl?: string;
}

export default function ArtistCredit({ artistName, artistUrl }: ArtistCreditProps) {
    const name = artistUrl
        ? (
            <StyledLink href={artistUrl} target="_blank" rel="noreferrer">
                {artistName}
            </StyledLink>
        ) : artistName;
    return (
        <Typography fontSize="0.75rem" mt={0.5} ml={1} height="18px">
            {!!artistName ? <>Artist: {name}</> : <></>}
        </Typography>
    )
}