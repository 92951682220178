import Podium from './Podium';
import { Stack } from '@mui/material';
import Option from '../types/Option';

type StandingsProps = {
    topVotes: Option[];
    hasVoted: boolean;
    animate: any;
    userVoteOptionId?: number;
};


export default function Standings({ topVotes, hasVoted, animate, userVoteOptionId }: StandingsProps) {
    return (
        <Stack direction="row" alignItems="flex-end" spacing={{ xs: 2, lg: 4 }}>
            {topVotes[1] && (<Podium place={1} option={topVotes[1]} hasVoted={hasVoted} animate={animate}
                userVoteOptionId={userVoteOptionId}/>)}
            {topVotes[0] && (<Podium place={0} option={topVotes[0]} hasVoted={hasVoted} animate={animate}
                userVoteOptionId={userVoteOptionId}/>)}
            {topVotes[2] && (<Podium place={2} option={topVotes[2]} hasVoted={hasVoted} animate={animate}
                userVoteOptionId={userVoteOptionId}/>)}
        </Stack>
    )
}