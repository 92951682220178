import { Stack } from '@mui/material';
import Lists from './Lists';

export default function HomePage() {
    return (
        <Stack width={{ xs: '100%', lg: '50%' }} spacing={10}>
            <Lists/>
        </Stack>
    );
}
