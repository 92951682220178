import { useParams } from 'react-router-dom';
import { fetchData, useGetListById, useGetVoteByUserAndList, votesEndpoint } from '../util/apiUtil';
import { CircularProgress, Fade, Stack, Typography } from '@mui/material';
import OptionCard from './OptionCard';
import { useEffect, useMemo, useState } from 'react';
import { getCookieValue, setCookieValue } from '../util/util';
import { useMutation } from '@tanstack/react-query';
import { orderBy, sortBy } from 'lodash';
import CastVote from './CastVote';
import Standings from './Standings';

const animate = {
    visible: {
        opacity: 1,
        transition: { duration: 2 }
    },
    hidden: {
        opacity: 0,
    }
}

export default function ListPage() {
    const { listId } = useParams();
    const { data: list, refetch: refetchList, isLoading: isListLoading } = useGetListById(listId || '');
    const [selectedOptionId, setSelectedOptionId] = useState<number | undefined>();
    const [userUID, setUserUID] = useState<string | undefined>();
    const {
        data: userVote,
        refetch: refetchUserVote,
    } = useGetVoteByUserAndList(userUID, list?.id);
    const hasVoted = !!userVote?.id;

    const topVotes = useMemo(() => orderBy(
        list?.options?.map(option => ({ ...option, voteCount: option.votes?.length || 0 })),
        'voteCount',
        'desc'),
    [list]);

    useEffect(() => {
        setUserUID(getCookieValue('userUID') || undefined);
    }, []);

    useEffect(() => {
        setSelectedOptionId(userVote?.optionId);
    }, [list, userUID, userVote]);

    const postVoteMutation = useMutation(
        {
            mutationFn: (newVote: { optionId: number, listId?: number, userUID?: string }) => {
                return fetchData('POST', votesEndpoint, newVote)
            },
            onSuccess: (data) => {
                if (!userUID) {
                    setUserUID(data.userUID);
                    setCookieValue('userUID', data.userUID);
                }
                refetchList();
                refetchUserVote();
            }
        }
    );

    const putVoteMutation = useMutation(
        {
            mutationFn: (variables: { voteId: number, vote: { optionId: number } }) => {
                return fetchData('PUT', `${votesEndpoint}/${variables.voteId}`, variables.vote)
            },
            onSuccess: () => {
                refetchList();
                refetchUserVote();
            }
        }
    );

    const handleOptionClick = (optionId: number) => {
        if (!selectedOptionId) {
            postVoteMutation.mutate({ optionId, listId: list?.id, userUID });
        } else if (optionId !== selectedOptionId) {
            !!userVote?.id && putVoteMutation.mutate({ voteId: userVote.id, vote: { optionId } });
        }
    }

    if (isListLoading) {
        return <CircularProgress color="primary" sx={{ mt: 40 }}/>
    }

    return (
        <Fade in={true} timeout={1000}>
            <Stack alignItems="center" spacing={2} width="100%">
                <Stack alignItems="center" spacing={1}>
                    <Typography variant="h4" fontWeight="bold" textAlign="center">{list?.name}</Typography>
                    {!!list?.description &&
                        <Typography fontWeight="medium" textAlign="center">{list?.description}</Typography>}
                </Stack>
                <Standings topVotes={topVotes} hasVoted={hasVoted} animate={animate}
                    userVoteOptionId={userVote?.optionId}/>
                <CastVote hasVoted={hasVoted}/>
                <Stack spacing={2} pt={{ xs: 2, lg: 4 }} width={{ xs: '100%', lg: '50%' }}>
                    {
                        sortBy(list?.options, ['createdAt']).map((option, index) => (
                            <OptionCard
                                key={index}
                                option={option}
                                onOptionClick={() => handleOptionClick(option.id)}
                                isSelected={selectedOptionId === option.id}
                                hasVoted={hasVoted}
                                animate={animate}
                            />
                        ))
                    }
                </Stack>
            </Stack>
        </Fade>
    );
}
