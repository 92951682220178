import { Box, Stack, Typography, useTheme } from '@mui/material';
import Option from '../types/Option';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { motion } from 'framer-motion';
import CardImage from '../CardImage';
import { getOptionImageObjectName } from '../util/util';
import ArtistCredit from '../ArtistCredit';

type OptionProps = {
    option: Option;
    onOptionClick: () => void;
    isSelected: boolean;
    hasVoted: boolean;
    animate: any;
}

export default function OptionCard({ option, onOptionClick, isSelected, hasVoted, animate }: OptionProps) {
    const { palette } = useTheme();

    const card = {
        hovered: {
            scale: 1.02,
            transition: {
                scale: {
                    type: 'spring',
                    bounce: 0.5,
                }
            }
        }
    }

    return (
        <Stack>
            <motion.div
                whileHover="hovered"
                variants={card}
                onClick={onOptionClick}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: { xs: 1.5, lg: 2 },
                        border: `1px solid ${isSelected ? palette.secondary.dark : palette.background.default}`,
                        borderRadius: { xs: 1, lg: 2 },
                        backgroundColor: isSelected ? palette.secondary.main : palette.background.paper,
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: isSelected ? palette.secondary.light : palette.background.paper
                        },
                        transition: 'all 500ms'
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <CardImage objectName={getOptionImageObjectName(option.id)}/>
                        <Stack ml={2}>
                            <Typography fontSize={{ xs: '1rem', lg: '1.5rem' }} fontWeight="medium">
                                {option.name}
                            </Typography>
                            {!!option.description && (
                                <Typography
                                    fontSize={{ xs: '0.75rem', lg: '1rem' }}
                                    fontWeight="light"
                                >
                                    {option.description}
                                </Typography>
                            )}
                        </Stack>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <motion.span
                            style={{ opacity: 0 }}
                            animate={hasVoted ? 'visible' : 'hidden'}
                            variants={animate}
                        >
                            <Typography fontSize={{ xs: '1rem', lg: '1.5rem' }} fontWeight="bold" mx={2}>
                                {option.votes.length} {option.votes.length === 1 ? 'vote' : 'votes'}
                            </Typography>
                        </motion.span>
                        <Box
                            sx={{
                                opacity: isSelected ? 1 : 0,
                                color: isSelected ? palette.secondary.dark : palette.secondary.main,
                                marginTop: '3px',
                                '&:hover': {
                                    opacity: 1,
                                },
                                transition: 'all 500ms'
                            }}
                        >
                            <CheckCircleIcon/>
                        </Box>
                    </Box>
                </Box>
            </motion.div>
            <ArtistCredit artistName={option.artistName} artistUrl={option.artistUrl}/>
        </Stack>
    )
}