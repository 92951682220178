import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createTheme, ThemeProvider } from "@mui/material";

const queryClient = new QueryClient();

const theme = createTheme({
    palette: {
        primary: {
            main: '#9d8189',
        },
        secondary: {
            main: '#D8E2DC',
        },
        background: {
            default: '#ffe5d9',
            paper: '#fff6f2',
        },
        text: {
            primary: '#514348',
        },
    },
    typography: {
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeightLight: 200,
        fontWeightRegular: 300,
        fontWeightMedium: 400,
        fontWeightBold: 500,
        allVariants: {
            color: '#514348'
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    </React.StrictMode>
);
