import { AppBar, Box, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import LogoWhite from './assets/logo-white.svg';

export default function Header() {
    const { breakpoints } = useTheme();
    const lg = useMediaQuery(breakpoints.up('lg'));

    return (
        <>
            <AppBar position="fixed">
                <Toolbar>
                    <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <img src={LogoWhite} alt="Logo" style={{ height: lg ? '1.8rem' : '1.5rem', marginRight: '5px' }}/>
                            <Typography
                                color="primary.contrastText"
                                fontFamily="Lilita One"
                                variant="h4"
                                fontSize={{ xs: '1.6rem', lg: '2.4rem' }}
                                component="a"
                                href="/"
                                sx={{ textDecoration: 'none' }}
                                my={1}
                            >
                                Fictional Favorites
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                color="primary.contrastText"
                                fontWeight={700}
                                component="a"
                                href="/contact"
                                sx={{ textDecoration: 'none' }}
                                mx={2}
                            >
                                CONTACT
                            </Typography>
                            {/*<Typography
                            color="primary.contrastText"
                            fontWeight={700}
                            component="a"
                            href="/"
                            sx={{ textDecoration: 'none' }}
                            mx={2}
                        >
                            ABOUT
                        </Typography>*/}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar/>
        </>
    )
}
