import { Fade, Stack, Typography } from '@mui/material';
import ListCard from './ListCard';
import { useGetLists } from '../util/apiUtil';

export default function Lists() {
    const { data: lists } = useGetLists();

    return (
        <Stack spacing={4} alignItems="center">
            <Typography variant="h4" fontWeight="bold" textAlign="center">Choose a list and cast your vote!</Typography>
            <Fade in={!!lists} timeout={1000}>
                <Stack spacing={2} width="100%">
                    {lists?.map((list, index) => <ListCard key={index} list={list}/>)}
                </Stack>
            </Fade>
        </Stack>
    )
}