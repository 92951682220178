import { useQuery } from '@tanstack/react-query';
import List from '../types/List';
import Vote from '../types/Vote';

const baseEndpoint = process.env.REACT_APP_API_URL;
const listsEndpoint = baseEndpoint + '/lists';
const votesEndpoint = baseEndpoint + '/votes';
const contactsEndpoint = baseEndpoint + '/contacts';

const fetchData = (method: 'GET' | 'POST' | 'PUT' | 'DELETE', endpoint: string, body?: any) =>
    fetch(endpoint, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }).then((response) => {
        return response.json().then((res) => res.data);
    });

const useGetLists = () => useQuery(
    ['lists'],
    (): Promise<List[]> => fetchData('GET', listsEndpoint)
);

const useGetListById = (id: string) => useQuery(
    ['lists', id],
    (): Promise<List> => fetchData('GET', `${listsEndpoint}/${id}`)
);

const useGetVoteByUserAndList = (userUID?: string, listId?: number) => useQuery({
    queryKey: ['votes', userUID, listId],
    queryFn: (): Promise<Vote> => fetchData('GET', `${votesEndpoint}/${userUID}/${listId}`),
    enabled: !!userUID && !!listId
});

const useGetImage = (objectName: string) => useQuery({
    queryKey: ['image', objectName],
    queryFn: () => fetch(`https://storage.googleapis.com/storage/v1/b/${process.env.REACT_APP_BUCKET_NAME}/o/${encodeURIComponent(objectName)}`, { method: 'GET' }).then((response) => response.json().then((res) => res))
});

export {
    useGetLists,
    useGetListById,
    useGetVoteByUserAndList,
    fetchData,
    votesEndpoint,
    useGetImage,
    contactsEndpoint,
};
