import { Box, Button, CircularProgress, Fade, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { contactsEndpoint, fetchData } from './util/apiUtil';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';

export default function ContactPage() {
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const postContactMutation = useMutation(
        {
            mutationFn: (newContact: { email?: string, subject?: string, body: string }) =>
                fetchData('POST', contactsEndpoint, newContact),
            onSuccess: (data) => {
                console.log('submitted: ', data);
                setEmail('');
                setSubject('');
                setBody('');
                setIsSnackbarOpen(true);
            },
            onSettled: () => setIsSubmitLoading(false),
        }
    );

    const handleSubmitContact = () => {
        setIsSubmitLoading(true);
        postContactMutation.mutate({ email, subject, body });
    };

    return (
        <Fade in={true} timeout={1000}>
            <Stack spacing={4} id="contact">
                <Stack>
                    <Typography variant="h4" fontWeight="bold">Contact</Typography>
                    <Typography>
                        Want to recommend a new list idea or just have a question? Feel free to reach out using the form
                        below!
                    </Typography>
                </Stack>
                <Stack component="form" spacing={4}>
                    <TextField
                        label="Email (optional)"
                        variant="outlined"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <TextField
                        label="Subject (optional)"
                        variant="outlined"
                        value={subject}
                        onChange={(event) => setSubject(event.target.value)}
                    />
                    <TextField
                        label="Body"
                        required
                        multiline rows={4}
                        variant="outlined"
                        value={body}
                        onChange={(event) => setBody(event.target.value)}
                    />
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            disabled={!body || isSubmitLoading}
                            onClick={handleSubmitContact}
                            endIcon={isSubmitLoading ? <CircularProgress size="1rem"/> : <SendIcon/>}
                        >
                            Submit
                        </Button>
                        <Snackbar
                            open={isSnackbarOpen}
                            autoHideDuration={6000}
                            onClose={() => setIsSnackbarOpen(false)}
                            message="Message submitted!"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            ContentProps={{
                                sx: {
                                    '&.MuiSnackbarContent-root': {
                                        minWidth: 'auto'
                                    }
                                }
                            }}
                            TransitionComponent={Fade}
                        />
                    </Box>
                </Stack>
            </Stack>
        </Fade>
    )
}