function getCookieValue(key: string) {
    const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${key}=`));
    return cookie ? cookie.split('=')[1] : undefined;
}

function setCookieValue(key: string, value: string) {
    document.cookie = `${key}=${value}`;
}

function getOptionImageObjectName(id: number) {
    return `options/${id}`;
}

function getListImageObjectName(id: number) {
    return `lists/${id}`;
}

export {
    getCookieValue,
    setCookieValue,
    getOptionImageObjectName,
    getListImageObjectName,
}