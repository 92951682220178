import List from '../types/List';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import CardImage from '../CardImage';
import { getListImageObjectName } from '../util/util';
import ArtistCredit from '../ArtistCredit';

type ListProps = {
    list: List;
}

export default function ListCard({ list }: ListProps) {
    const { palette, spacing, breakpoints } = useTheme();
    const totalVotes = list.votes.length;
    const lg = useMediaQuery(breakpoints.up('lg'));

    return (
        <Stack width="100%">
            <motion.a
                href={`/lists/${list.id}`}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textDecoration: 'none',
                    padding: spacing(2),
                    borderRadius: spacing(lg ? 1 : 0.5),
                    boxSizing: 'border-box',
                    backgroundColor: palette.background.paper,
                    cursor: 'pointer',
                }}
                whileHover={{
                    scale: 1.02,
                    transition: { type: 'spring', bounce: 0.5 }
                }}
            >
                <Box display="flex" alignItems="center">
                    <CardImage objectName={getListImageObjectName(list.id)}/>
                    <Typography fontWeight="medium" ml={2}>{list.name}</Typography>
                </Box>
                <Typography fontWeight="medium"
                    textAlign="center">{totalVotes} {totalVotes === 1 ? 'vote' : 'votes'}</Typography>
            </motion.a>
            <ArtistCredit artistName={list.artistName} artistUrl={list.artistUrl}/>
        </Stack>
    );
}