import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { useGetImage } from './util/apiUtil';

const StyledImage = styled('img')(({ theme }) => ({
    width: '50px',
    maxHeight: '50px',
    flexShrink: 0,
    objectFit: 'cover',
    objectPosition: 'top',
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.up('lg')]: {
        width: '100px',
        maxHeight: '100px',
        borderRadius: theme.spacing(1),
    },
}));

type CardImageProps = {
    objectName: string;
}

export default function CardImage({ objectName }: CardImageProps) {
    const { data: image } = useGetImage(objectName);
    const { breakpoints } = useTheme();
    const lg = useMediaQuery(breakpoints.up('lg'));

    return (
        !!image?.mediaLink
            ? <StyledImage src={image.mediaLink}/>
            : (
                <Box
                    width={lg ? '100px' : '50px'}
                    height={lg ? '100px' : '50px'}
                    borderRadius={lg ? 1 : 0.5}
                    bgcolor="primary.main"
                />
            )
    )
}