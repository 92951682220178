import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './Home/HomePage';
import ListPage from './List/ListPage';
import Root from './Root';
import ContactPage from './ContactPage';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        // errorElement: <ErrorPage/>,
        children: [
            {
                path: '/lists/:listId',
                element: <ListPage/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '/',
                element: <HomePage/>
            }
        ]
    }
]);

function App() {
    return (
        <RouterProvider router={router}/>
    );
}

export default App;
