import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './Header';

export default function Root() {
    return (
        <Stack
            p={{ xs: 3, lg: 5 }}
            pb={{ xs: 5, lg: 10 }}
            minHeight="100vh"
            height="100%"
            bgcolor="background.default"
            boxSizing="border-box"
            width="100%"
            alignItems="center"
        >
            <Header/>
            <Outlet/>
        </Stack>
    )
}