import ErrorIcon from '@mui/icons-material/Error';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

export default function CastVote({ hasVoted }: { hasVoted: boolean }) {
    const { palette, spacing, breakpoints } = useTheme();
    const lg = useMediaQuery(breakpoints.up('lg'));

    return (
        <motion.div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: 0,
                height: 0,
                marginTop: 10,
                backgroundColor: palette.secondary.main,
                padding: '0 10px',
                borderRadius: spacing(lg ? 1 : 0.5),
            }}
            animate={{
                y: !hasVoted ? [0, -10, 5, 0] : [],
                opacity: !hasVoted ? 1 : 0,
                height: !hasVoted ? 45 : 0
            }}
            transition={{
                y: {
                    repeat: Infinity,
                    repeatDelay: 3,
                    duration: 0.5,
                    bounce: 0.8,
                },
                opacity: { duration: 1, delay: !hasVoted ? 1 : 0.5 },
                height: { duration: 1, delay: !hasVoted ? 0.5 : 1 }
            }}
        >
            <ErrorIcon sx={{ color: palette.secondary.dark }}/>
            <Typography ml={1} color={palette.secondary.dark} fontSize="1rem">Cast your vote to reveal the standings</Typography>
        </motion.div>
    )
}