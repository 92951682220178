import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Option from '../types/Option';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { motion } from 'framer-motion';
import { useGetImage } from '../util/apiUtil';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getOptionImageObjectName } from '../util/util';

type PodiumProps = {
    place: number;
    option: Option;
    hasVoted: boolean;
    animate: any;
    userVoteOptionId?: number;
};

export default function Podium({ place, option, hasVoted, animate, userVoteOptionId }: PodiumProps) {
    const { data: image } = useGetImage(getOptionImageObjectName(option.id));
    const { breakpoints, spacing, palette } = useTheme();
    const lg = useMediaQuery(breakpoints.up('lg'))
    const getWidth = () => {
        switch (place) {
        case 2:
            return lg ? '100px' : '70px';
        case 1:
            return lg ? '150px' : '85px';
        default:
            return lg ? '200px' : '115px';
        }
    }

    return (
        <Stack alignItems="center" spacing={1}>
            <motion.div
                style={{
                    opacity: 0,
                    display: 'flex',
                    alignItems: 'center'
                }}
                animate={hasVoted ? 'visible' : 'hidden'}
                variants={animate}
            >
                <Typography variant="h6" textAlign="center">{option.name}</Typography>
                {option?.id === userVoteOptionId &&
                    <CheckCircleIcon sx={{ color: palette.secondary.dark, ml: 0.5 }} fontSize="small"/>
                }
            </motion.div>
            {hasVoted && !!image?.mediaLink
                ? <motion.img
                    style={{
                        borderRadius: spacing(lg ? 1 : 0.5),
                        opacity: 0,
                        objectFit: 'cover',
                        objectPosition: 'top'
                    }}
                    src={image.mediaLink}
                    width={getWidth()}
                    height={getWidth()}
                    animate={hasVoted ? 'visible' : 'hidden'}
                    variants={animate}
                />
                : <motion.div
                    style={{
                        width: getWidth(),
                        height: getWidth(),
                        backgroundColor: palette.primary.main,
                        borderRadius: spacing(lg ? 1 : 0.5),
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: palette.primary.contrastText,
                        opacity: 0,
                    }}
                    animate="visible"
                    variants={animate}
                >
                    <QuestionMarkIcon fontSize="large"/>
                </motion.div>
            }
            <motion.span
                style={{ opacity: 0 }}
                animate={hasVoted ? 'visible' : 'hidden'}
                variants={animate}
            >
                <Typography fontWeight="regular">
                    {option.voteCount} {option.voteCount === 1 ? 'vote' : 'votes'}
                </Typography>
            </motion.span>
        </Stack>
    )
}